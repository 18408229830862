import {
  Box,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Link as MUILink,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  AccountCircle,
  Logout,
  DashboardCustomizeOutlined,
  RequestQuoteOutlined,
  Assessment,
  SettingsApplicationsOutlined,
  SettingsAccessibilityOutlined,
} from '@mui/icons-material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { SideBar } from '../';
import LogoImage from '../../assets/images/fundis-logo.png';

function MenuBar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));
  // const extraLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const navLinkStyle = ({ isActive }) => ({
    color: isActive ? '#FF5318' : 'inherit',
    fontWeight: isActive ? 'bold' : 'normal',
  });

  const userType = localStorage.getItem('user-role');

  const logout = () => {
    window.localStorage.clear();
    window.location.reload('/');
  };

  return (
    <>
      {!largeScreen && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            bgcolor: 'background.default',
          }}
          paddingLeft={1}
          paddingY={1}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box component="img" src={LogoImage} alt="Fundis Logo" />
            <Typography variant="h6" component="span" fontWeight="bold">
              FUNDIS
            </Typography>
            {/* <Logo /> */}
          </Box>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          {(userType === '"super-admin"' || userType === '"admin"') && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{
                top: '0.75rem',
              }}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
                sx: {
                  minWidth: { xs: '100vw', sm: '300px' },
                  boxShadow: 'none',
                  borderRadius: '0px',
                },
              }}
            >
              <MenuItem>
                <MUILink
                  underline="none"
                  component={NavLink}
                  to="/"
                  color="inherit"
                  style={navLinkStyle}
                >
                  <IconButton>
                    <DashboardCustomizeOutlined />
                  </IconButton>
                  Dashboard
                </MUILink>
              </MenuItem>
              <MenuItem>
                <MUILink
                  underline="none"
                  component={NavLink}
                  to="job-requests"
                  color="inherit"
                  style={navLinkStyle}
                >
                  <IconButton>
                    <RequestQuoteOutlined />
                  </IconButton>
                  Requests
                </MUILink>
              </MenuItem>
              <MenuItem>
                <MUILink
                  underline="none"
                  component={NavLink}
                  to="all-customers"
                  color="inherit"
                  style={navLinkStyle}
                >
                  <IconButton>
                    <AccountCircle />
                  </IconButton>
                  Customers
                </MUILink>
              </MenuItem>
              <MenuItem>
                <MUILink
                  underline="none"
                  component={NavLink}
                  to="artisans"
                  color="inherit"
                  style={navLinkStyle}
                >
                  <IconButton>
                    <AccountCircle />
                  </IconButton>
                  Artisans
                </MUILink>
              </MenuItem>
              <MenuItem>
                <MUILink
                  underline="none"
                  component={NavLink}
                  to="quotations"
                  color="inherit"
                  style={navLinkStyle}
                >
                  <IconButton>
                    <ReceiptIcon />
                  </IconButton>
                  Quotations
                </MUILink>
              </MenuItem>
              <MenuItem>
                <MUILink
                  underline="none"
                  component={NavLink}
                  to="receipt"
                  color="inherit"
                  style={navLinkStyle}
                >
                  <IconButton>
                    <ReceiptIcon />
                  </IconButton>
                  Receipt
                </MUILink>
              </MenuItem>
              <MenuItem>
                <MUILink
                  underline="none"
                  component={NavLink}
                  to="assessments"
                  color="inherit"
                  style={navLinkStyle}
                >
                  <IconButton>
                    <Assessment />
                  </IconButton>
                  Assessments
                </MUILink>
              </MenuItem>
              <MenuItem>
                <MUILink
                  underline="none"
                  component={NavLink}
                  to="app-management"
                  color="inherit"
                  style={navLinkStyle}
                >
                  <IconButton>
                    <SettingsApplicationsOutlined />
                  </IconButton>
                  App Management
                </MUILink>
              </MenuItem>
              {userType === 'super-admin' && (
                <MenuItem>
                  <MUILink
                    underline="none"
                    component={NavLink}
                    to="roles-functions"
                    color="inherit"
                    style={navLinkStyle}
                  >
                    <IconButton>
                      <SettingsAccessibilityOutlined />
                    </IconButton>
                    Roles
                  </MUILink>
                </MenuItem>
              )}
              <MUILink
                underline="none"
                component={NavLink}
                to=""
                onClick={() => {
                  logout();
                }}
                color="inherit"
                style={navLinkStyle}
              >
                <MenuItem>
                  <IconButton>
                    <Logout />
                  </IconButton>
                  Logout
                </MenuItem>
              </MUILink>
            </Menu>
          )}
          {userType === '"assessor"' && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{
                top: '0.75rem',
              }}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
                sx: {
                  minWidth: { xs: '100vw', sm: '300px' },
                  boxShadow: 'none',
                  borderRadius: '0px',
                },
              }}
            >
              <MenuItem>
                <MUILink
                  underline="none"
                  component={NavLink}
                  to="/"
                  color="inherit"
                  style={navLinkStyle}
                >
                  <IconButton>
                    <DashboardCustomizeOutlined />
                  </IconButton>
                  Dashboard
                </MUILink>
              </MenuItem>
              <MenuItem>
                <MUILink
                  underline="none"
                  component={NavLink}
                  to="assessments"
                  color="inherit"
                  style={navLinkStyle}
                >
                  <IconButton>
                    <Assessment />
                  </IconButton>
                  Assessments
                </MUILink>
              </MenuItem>
              <MUILink
                underline="none"
                component={NavLink}
                to=""
                onClick={() => {
                  logout();
                }}
                color="inherit"
                style={navLinkStyle}
              >
                <MenuItem>
                  <IconButton>
                    <Logout />
                  </IconButton>
                  Logout
                </MenuItem>
              </MUILink>
            </Menu>
          )}
        </Box>
      )}
      {largeScreen && <SideBar />}
    </>
  );
}

export default MenuBar;
