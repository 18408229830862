import axios from 'axios';

export async function createCompetency (data) {
  try {
    const response = await axios({
      method: 'post',
      data,
      url: '/admin/assessments/competency'
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function updateCompetency (data, assessId) {
  try {
    const response = await axios({
      method: 'patch',
      data,
      url: `/admin/assessments/competency/${assessId}`
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function createUpskill (data) {
  try {
    const response = await axios({
      method: 'post',
      data,
      url: '/admin/assessments/upskill'
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function updateUpskill (data, assessId) {
  try {
    const response = await axios({
      method: 'patch',
      data,
      url: `/admin/assessments/upskill/${assessId}`
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function createVetting (data) {
  try {
    const response = await axios({
      method: 'post',
      data,
      url: '/admin/assessments/vetting'
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function updateVetting (data, assessId) {
  try {
    const response = await axios({
      method: 'patch',
      data,
      url: `/admin/assessments/vetting/${assessId}`
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
