/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  Grid,
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import moment from 'moment';
import { DataGrid } from '../components';
import {
  fetchAllCustomers,
  exportUserData,
  deactivateOneCustomer,
  activateOneCustomer,
  deleteOneCustomer
} from '../api';

function Index() {
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState(15);

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [pageMessage, setPageMessage] = useState('');
  const [pageError, setPageError] = useState();

  const [confirmDelete, setConfirmDelete] = useState(false);

  const columns = [
    { field: 'name', headerName: 'Customer Name', width: 250 },
    {
      field: 'email',
      headerName: 'Email',
      width: 250
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      width: 200
    },
    {
      field: 'verified',
      headerName: 'Verified',
      width: 160
    },
    {
      field: 'action',
      headerName: 'Deactivate account',
      sortable: true,
      width: 160,
      renderCell: (params) => {
        const onClickDeactivate = async (e) => {
          e.stopPropagation(); // don't select this row after clicking

          await deactivateCustomer(params?.id);
          window.location.reload();
        };

        const onClickActivate = async (e) => {
          e.stopPropagation(); // don't select this row after clicking

          await activateCustomer(params?.id);
          window.location.reload();
        };

        if (params?.row?.is_active === true) {
          return <Button onClick={onClickDeactivate}>Deactivate</Button>;
        } else {
          return <Button onClick={onClickActivate}>Activate</Button>;
        }
      }
    },
    {
      field: 'delete',
      headerName: 'Delete account',
      sortable: true,
      width: 160,
      renderCell: (params) => {
        const onClickDelete = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          await deleteCustomer(params?.id);
          window.location.reload();
        };

        return (
          <div>
            <Button onClick={() => setConfirmDelete(true)}>
              Delete account
            </Button>
            <Dialog
              open={confirmDelete}
              onClose={() => setConfirmDelete(false)}
              aria-labelledby="confirm-dialog"
            >
              <DialogTitle>Delete Customer</DialogTitle>
              <DialogContent>
                Are you sure you want to delete this customer account?
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={() => {
                    setConfirmDelete(false);
                  }}
                  color="secondary"
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    setConfirmDelete(false);
                    onClickDelete(e);
                  }}
                  color="primary"
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      }
    }
  ];

  const deactivateCustomer = async (id) => {
    setLoading(true);
    const response = await deactivateOneCustomer(id);
    setLoading(false);

    if (response?.success) {
      setPageMessage('User account has been deacticated.');
    } else {
      setPageError('There is a problem with this account');
    }
  };

  const activateCustomer = async (id) => {
    setLoading(true);
    const response = await activateOneCustomer(id);
    setLoading(false);

    if (response?.success) {
      setPageMessage('User account has been reactivated.');
    } else {
      setPageError('You are unable to activate this account at the moment.');
    }
  };

  const deleteCustomer = async (id) => {
    setLoading(true);
    const response = await deleteOneCustomer(id);
    setLoading(false);

    if (response?.success) {
      setPageMessage('User account has been DELETED.');
    } else {
      setPageError('You are unable to delete this account at the moment.');
    }
  };

  const getJobRequests = async (page) => {
    setLoading(true);
    const response = await fetchAllCustomers(page + 1);
    setLoading(false);

    if (response?.success && response?.data?.data) {
      const { data } = response;
      const rows = data?.data.map((customerData) => ({
        ...customerData,
        name: `${customerData?.first_name} ${customerData?.last_name}`,
        verified: customerData?.phone_verified
      }));

      setRowData(rows);
      setRowCount(data?.total);
    }
  };

  const handleExport = async () => {
    setLoading(true);

    const response = await exportUserData('customer');

    const a = document.createElement('a');
    const date = moment().format('YYYY-MM-DD');

    a.download = `AllCustomers_export_output_${date}.csv`;
    a.href = response?.data?.report;

    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    a.dispatchEvent(clickEvt);
    a.remove();

    setLoading(false);
  };

  const getPage = async (page) => {
    await getJobRequests(page);
    setPage(page);
  };

  useEffect(() => {
    getJobRequests(page);
  }, [page]);

  return (
    <Grid container>
      {pageMessage && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          <strong>{pageMessage}</strong>
        </Alert>
      )}

      {pageError && <Alert severity="error">{pageError}</Alert>}

      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={{ lg: 'lg', xl: 'lg' }}
        p={5}
        minHeight="100vh"
        container
        flexGrow={1}
      >
        <Grid container>
          <Grid item lg={6}>
            <Typography variant="h5" mb={3}>
              All Customers
            </Typography>
          </Grid>
          <Grid item lg={6} sm={4} md={4}>
            <Button
              // pb={2}
              variant="outlined"
              sx={{ width: 300, float: 'right' }}
              onClick={() => handleExport()}
            >
              Export
            </Button>
          </Grid>
        </Grid>
        <Box width="100%">
          <DataGrid
            rowCount={rowCount}
            rows={rowData}
            columns={columns}
            loading={loading}
            pageSize={pageSize}
            onPageChange={getPage}
            page={page}
            autoHeight
            rowsPerPageOptions={[15]}
            paginationMode="server"
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
          />
        </Box>
      </Box>
    </Grid>
  );
}

export default Index;
