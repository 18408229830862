import axios from 'axios';

export async function fetchAllCustomers (pageNo = 1) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/users/customer?page=${pageNo}`
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchOneCustomer (id) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/users/customer/${id}`
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function reassignJob (jobId, artisanId) {
  const token = localStorage.getItem('token');

  const data = {
    artisan_id: artisanId
  };
  try {
    const response = await axios({
      method: 'patch',
      data,
      url: `/admin/job-request/${jobId}/reassign`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? data.message
            : 'We are unable to reassign/assign an artisan now. Please contact the tech team'
      },
      errors: data.data
    };
  }
}

export async function fetchOneArtisan (id) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/users/artisan/${id}`
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchAllArtisans (pageNo = 1) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/users/artisan?page=${pageNo}`
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchAllQuotations (pageNo = 1) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/quotations?status=pending&page=${pageNo}`
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchAllSuccessReceipts (pageNo = 1) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/transactions?status=SUCCESSFUL&page=${pageNo}`
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchAllReceipts (pageNo = 1) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/transactions?page=${pageNo}`
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function deactivateOneCustomer (id) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: 'PATCH',
      url: `/admin/deactivate/${id}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function activateOneCustomer (id) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: 'PATCH',
      url: `/admin/reactivate/${id}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function deleteOneCustomer (id) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: 'DELETE',
      url: `/admin/delete/${id}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
